/* .styled-button {
    top: -5%;
    left: -15%;
    content: '';
} */

.styled-button {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.styled-button::before {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100%;
    height: 100%;
    background-color: #A3B2B9;
    opacity: 0.55;

    content: '';
    border-radius: 5px;
    box-sizing: content-box;
    z-index: -1;
}

.styled-button:hover::before {
    background-color: #A3B2B9;
    opacity: 1;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper:after {
    content: '\0025BC';
    font: normal normal normal 12px/1 FontAwesome;
    color: rgb(1 100 178);
    right: 14px;
    top: -12%;
    height: 26px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}